export type SectionType = 'model' | 'scan' | 'it' | 'integration' | 'inventory' | 'digitization' | 'bim';

export enum SectionTypeEnum {
  DIGITIZATION = 'digitization',
  INVENTORY = 'inventory',
  BIM = 'bim',
  INTEGRATION = 'integration',
  IT = 'it',
  SCAN = 'scan',
  MODEL = 'model',
}

export enum UrlSectionEnum {
  PRZEBUDOWY_BIM = 'przebudowy_BIM',
  INWENTARYZACJA_BIM = 'inwentaryzacja_BIM',
  HERITAGE_BIM = 'heritage_BIM',
  MODEL_INFORMACJE = 'model_informacje',
  MODEL_3D = 'model_3D_web',
}

export const sectionMap: { [key in UrlSectionEnum]: SectionTypeEnum } = {
  [UrlSectionEnum.PRZEBUDOWY_BIM]: SectionTypeEnum.BIM,
  [UrlSectionEnum.INWENTARYZACJA_BIM]: SectionTypeEnum.INVENTORY,
  [UrlSectionEnum.HERITAGE_BIM]: SectionTypeEnum.DIGITIZATION,
  [UrlSectionEnum.MODEL_INFORMACJE]: SectionTypeEnum.INTEGRATION,
  [UrlSectionEnum.MODEL_3D]: SectionTypeEnum.IT,
};