import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeroComponent } from './components/hero/hero.component';
import { PartnersComponent } from './components/partners/partners.component';
import {SectionType, SectionTypeEnum, UrlSectionEnum, sectionMap } from '../../../../shared/model';
import { MediaQueryService } from '../../services/media-query.service';
import { MainService } from '../../services/main.service';
import {ActivatedRoute} from "@angular/router";

interface SectionConfig {
  icons: number;
  section1: number;
  section2?: number;
}

interface AppConfig {
  [key: string]: SectionConfig;
}

@Component({
  standalone: true,
  selector: 'app-main-section',
  imports: [
    TranslateModule,
    CommonModule,
    MatButtonModule,
    SlickCarouselModule,
    HeroComponent,
    PartnersComponent,
  ],
  templateUrl: 'main.component.html',
  styleUrl: 'main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MainComponent implements OnInit {
  private changeDetectorRef = inject(ChangeDetectorRef);
  public mediaQueryService = inject(MediaQueryService);
  public mainService = inject(MainService);
  public route = inject(ActivatedRoute);

  public type: SectionType = this.mainService.activeType$.value;
  public slickConfig = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  public config: AppConfig = {
    'model': {
      icons: 5,
      section1: 4
    },
    'scan': {
      icons: 5,
      section1: 4
    },
    'it': {
      icons: 4,
      section1: 3,
      section2: 3,
    },
    'integration': {
      icons: 4,
      section1: 3,
      section2: 3,
    },
    'inventory': {
      icons: 4,
      section1: 4,
      section2: 3,
    },
    'digitization': {
      icons: 4,
      section1: 4,
      section2: 3,
    },
    'bim': {
      icons: 4,
      section1: 5,
      section2: 3,
    },
  };

  public ngOnInit(): void {
    const sectionTypeFromUrl = this.route.snapshot.paramMap.get('category') as UrlSectionEnum;

    if (sectionTypeFromUrl && sectionMap[sectionTypeFromUrl]) {
      setTimeout(() => {
        const matchingEnumValue = sectionMap[sectionTypeFromUrl];

        if (matchingEnumValue) {
          this.mainService.activeType$.next(matchingEnumValue);

          const mainElement = document.querySelector('.main-section');
          if (mainElement) {
            mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      }, 1000);
    }

    this.mainService.activeType$.subscribe((type: SectionTypeEnum) => {
      this.type = (type.toLowerCase() as SectionType);
      this.changeDetectorRef.markForCheck();
    });
  }

  public setType(type: string) {
    this.type = type as SectionType;

    this.mainService.activeType$.next(this.type as SectionTypeEnum);
    this.changeDetectorRef.detectChanges();
  }

  protected readonly SectionTypeEnum = SectionTypeEnum;
}